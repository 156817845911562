import { TeaserGridSection } from 'components/UI/TeaserGridSectionNew';
import { TeaserNew } from 'components/UI/TeaserNew';
import type { WidgetTeaserStandardProps } from '../../../../../types/cms-teaser';

const WidgetTeaserSmall = ({ context }: { context: WidgetTeaserStandardProps }) => {
  const width = 137;

  return (
    <TeaserGridSection
      title={context.model?.fields?.title}
      titleLink={context.model?.fields?.link}
      anchor={context.model?.fields?.anchor}
    >
      {context.items?.map((item) => (
        <TeaserNew
          key={item.model.id}
          variant="small"
          title={item.model.fields.title}
          image={{ image: item.model.image, width: width, alt: `Bild zu: "${item.model.fields.title}"` }}
          linkHref={item.model.fields?.externalUrl ?? item.model.url}
          isVideo={!!item.model.fields.encoding?.[0]?.value?.duration?.value}
          videoDuration={Number(item.model.fields.encoding?.[0]?.value?.duration?.value)}
          isSponsored={item.type === 'sponsored-article'}
          openLinkInNewTab={!!item.model.fields?.externalUrl}
        />
      ))}
    </TeaserGridSection>
  );
};

export default WidgetTeaserSmall;
